import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Clean 4-4-4-4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-Calorie Assault Bike`}</p>
    <p>{`40-Wall Balls (20/14)`}</p>
    <p>{`20-Thrusters (115/75)`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-Calorie Assault Bike`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`15-Power Cleans (135/95)`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`10-Calorie Assault Bike`}</p>
    <p>{`20-Wall Balls`}</p>
    <p>{`10-Squat Cleans (155/105)`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      